<template>
  <div class="container">
    <van-field
      readonly
      clickable
      name="datetimePicker"
      :value="searchTime"
      label="报修时间"
      placeholder="点击选择时间"
      @click="showPicker = true"
    />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getRecordList"
    >
      <div class="recordsList">
        <div
          class="recordCon"
          v-for="item in recordList"
          :key="item.id"
          @click="goToDetail(item.id)"
        >
          <div class="top">
            <div class="left">报修项目：{{ item.repairItem }}</div>
            <div class="right">
              {{ item.repairType == 8001 ? '公共报修' : '个人报修' }}
            </div>
          </div>
          <div class="li"><span>报修描述：</span>{{ item.remark }}</div>
          <div class="li"><span>报修地点：</span>{{ item.address }}</div>
          <div class="li"><span>报修时间：</span>{{ item.updateTime }}</div>
          <!-- <div :class="['bottom', item.auditStatus == '3' ? 'red' : 'blue']">
            {{ auditStatusDict[item.auditStatus] }}
          </div> -->
          <div :class="['bottom', item.propertyStatus == '-2' ? 'red' : 'blue']">
            {{ auditStatusDict(item.propertyStatus) }}
          </div>
        </div>
      </div>
    </van-list>

    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="updateTime"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { dateFormat } from '@/utils/date'
import { repairList } from '@/api/repairApi'

export default {
  
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      currentList: -1,
      showPicker: false,
      
      recordList: [],
      searchTime: '',
      updateTime: '',
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
    }
  },
  created() {},
  computed: {
    auditStatusDict() {
      return status => {
        switch (status) {
          case -2:
            return '物业驳回'
          case -1:
            return '已撤销'
          case 0:
            return '待维修'
          case 1:
            return '维修中'
          case 2:
            return '维修完成'
          case 3:
            return '已解决'
          case 4:
            return '未解决'
        }
      }
    },
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    onConfirm(val) {
      this.updateTime = dateFormat(val, 'YYYY-MM')
      let arr = this.updateTime.split('-')
      this.searchTime = `${arr[0]}年${arr[1]}月`
      this.page = 1
      this.currentList = -1
      this.recordList = []
      this.getRecordList(this.updateTime)
      this.showPicker = false
    },
    getRecordList(time) {
      if (this.currentList !== 0) {
        repairList({
          openId: this.$store.getters.openId,
          current: this.page,
          uTime: time,
        }).then(res => {
          if (res.code == 200) {
            this.loading = false
            this.currentList = res.data.records ? res.data.records.length : 0
            this.recordList = this.recordList.concat(res.data.records)
            this.page++
          } else {
            return this.$toast({
              type: 'error',
              message: res.msg,
              duration: 500,
            })
          }
        })
      } else {
        this.finished = true
      }
    },
    goToDetail(id) {
      this.$router.push({ path: '/recordsDetails', query: { id: id } })
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}
.recordsList {
  padding-bottom: 40px;
}
.recordCon {
  width: 94vw;
  height: auto;
  margin: 15px auto 0;
  border: 1px solid #cae7fe;
  padding: 15px;
  .top {
    display: flex;
    justify-content: space-between;
    .right {
      border: 1px solid #333333;
      padding: 2px 4px;
    }
  }
  .li {
    color: #7e7e7e;
    padding-top: 5px;
    span {
      color: #333333;
    }
  }
  .bottom {
    text-align: right;
    font-size: 16px;
  }
  .red {
    color: #f84c5e;
  }
  .blue {
    color: #4cb8fd;
  }
}
</style>
